import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '上海合远私募基金管理有限公司'
    }
  },
  {
    path: '/hy',
    name: 'Hy',
    meta: {
      title: '走进合远'
    },
    component: () => import('@/views/goHy')
  },
  {
    path: '/connect',
    name: 'Connect',
    meta: {
      title: '联系我们'
    },
    component: () => import('@/views/connect')
  },
  {
    path: '/joinHy',
    name: 'JoinHy',
    meta: {
      title: '加入合远'
    },
    component: () => import('@/views/joinHy')
  },
  {
    path: '/dynamic',
    name: 'Dynamic',
    meta: {
      title: '合远动态'
    },
    component: () => import('@/views/dynamic')
  },
  {
    path: '/detail',
    name: 'Detail',
    meta: {
      title: '动态详情'
    },
    component: () => import('@/views/dynamic/detail')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    meta: {
      title: '留言提交'
    },
    component: () => import('@/views/feedback')
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior: () => ({ y: 0 }),
})
router.beforeEach((to, from, next) => {
  to.meta.title && (document.title = to.meta.title)
  next()
})

export default router
