<template>
  <div>
    <div class="navWrap" :style="navActive ? styleActive : ''">
      <div class="navWrap_inner">
        <div class="nav_top">
          <div v-if="$route.path === '/' || $route.path === '/dynamic' || $route.path === '/hy' || $route.path === '/connect' || $route.path === '/feedback'" class="left" @click="$router.push('/')">
            <img class="left_img" src="@/assets/images/4.png">
          </div>
          <div v-if="$route.path === '/joinHy'" class="left" @click="$router.push('/')">
            <img class="left_img" src="@/assets/images/20.png">
          </div>
          <ul class="right">
            <li class="item" @click="$route.path !== '/' && $router.push('/')"> 首页 </li>
            <li class="line" :style="navActive && $route.path !== '/dynamic' && $route.path !== '/' && $route.path !== '/hy' && $route.path !== '/connect' ? lineStyle : ''"></li>
            <li class="item"> 
              <span @click="$route.path !== '/hy' && $router.push('/hy')">走进合远</span> 
              <ul class="childNav">
                <li class="childNavItem" @click="goAnchar('Jj')">公司简介</li>
                <li class="childNavItem" @click="goAnchar('Jg')">组织架构</li>
                <li class="childNavItem" @click="goAnchar('Cl')">投资策略</li>
                <li class="childNavItem" @click="goAnchar('team')">核心团队</li>
                <li class="childNavItem" @click="goAnchar('honor')">所获荣誉</li>
                <li class="childNavItem" @click="goAnchar('Gl')">风险管理</li>
              </ul>
            </li>
            <li class="line" :style="navActive && $route.path !== '/dynamic' && $route.path !== '/' && $route.path !== '/hy' && $route.path !== '/connect' ? lineStyle : ''"></li>
            <li class="item" @click="$route.path !== '/dynamic' && $router.push('/dynamic')"> 合远动态 </li>
            <li class="line" :style="navActive && $route.path !== '/dynamic' && $route.path !== '/' && $route.path !== '/hy' && $route.path !== '/connect' ? lineStyle : ''"></li>
            <li class="item" @click="$route.path !== '/joinHy' && $router.push('/joinHy')"> 加入合远 </li>
            <li class="line" :style="navActive && $route.path !== '/dynamic' && $route.path !== '/' && $route.path !== '/hy' && $route.path !== '/connect' ? lineStyle : ''"></li>
            <li class="item" @click="$route.path !== '/connect' && $router.push('/connect')"> 联系我们 </li>
            <li class="line" :style="navActive && $route.path !== '/dynamic' && $route.path !== '/' && $route.path !== '/hy' && $route.path !== '/connect'  ? lineStyle : ''"></li>
            <li class="item" @click="jumpToCBS"> 投资者服务 </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data(){
      return{
        navActive: false,
        lineStyle: 'background: #325072',
        styleActive: 'background-color: #325072'
      }
    },
    mounted(){
      window.addEventListener('scroll', this.scrollHandle)
      let id = this.$route.query.id
      if(id) {
        this.goAnchar(id)
      }
    },
    methods:{
      jumpToCBS() {
        window.open('https://service.united-advance.com.cn/ztcbsweb/investorservice/pc/login.htm?authStr=61705562524D3265442B56524749745A787936537A577A5841494D374E792B4E65356D4172664B4B4D59593D')
      },
      scrollHandle() {
        let scrollY = document.documentElement.scrollTop
        if(scrollY >= 150) {
          this.navActive = true
          if( this.$route.path === '/joinHy') {
            this.styleActive = 'background-color: #fff;color:#325072;bax-shadow: 0 5px 0 2px #eee'
          }
        }else{
          this.navActive = false
        }
      },
      goAnchar(id) {
        if(this.$route.path === '/hy') {
          let anchar = document.getElementById(id) // 锚点id选择器名称
          let distance = 0
          let total = anchar.offsetTop - 120
          let step = total / 50 // 每次滑动距离
          jump()
          function jump(){
            if(distance < total){
                distance += step
                document.documentElement.scrollTop = distance
                document.body.scrollTop = distance
              setTimeout(jump, 1)
            }else{
                document.documentElement.scrollTop = total
                document.body.scrollTop = distance
            }
          }
        }else {
          this.$router.push('/hy?id=' + id)
        }
      }
    },
  }
</script>
<style scoped lang="less">
.navWrap{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    color: rgb(255 255 255);
    // transition: .2s;
    z-index: 100;
    .navWrap_inner{
      width: 1200px;
      margin: 0 auto;
      .nav_top{
        height: 95px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
          width: 350px;
          .left_img{
            width:100%;
            object-fit: contain;
            cursor: pointer;
          }
        }
        .right{
          display: flex;
          align-items:center;
          font-size: 16px;
          .item{
            position: relative;
            cursor: pointer;
            transition: .3s;
            .childNav{
              display: none;
              position: absolute;
              left: -8px;
              // top: 35px;
              width: 80px;
              color: #325072;
              padding-top: 15px;
              text-align: center;
              .childNavItem{
                line-height: 40px;
                background-color: #5086b9;
                transition: .3s;
                color: #fff;
                &:first-child {
                  padding-top: 5px
                }
                &:last-child{
                  padding-bottom: 5px;
                }
              }
            }
            &:hover .childNav{
              display: block;
            }
          }
          .item:hover{
            color: rgba(0,0,0,.8);
          }
          .line{
            width: 1.5px;
            height: 14px;
            margin: 0 25px;
            background-color: rgb(255 255 255);
            transition: .2s;
          }
        }
      }
    }
  }
</style>